import React, { useState, useCallback } from 'react';
import { TextField, ListItem, ListItemText, CircularProgress, Typography, Button } from '@mui/material';
import { FixedSizeList } from 'react-window';
import axios from 'axios';
import { API_ENDPOINTS } from '../config';

const FoodSearch = ({ onFoodSelect }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const itemsPerPage = 20;

  const searchFood = useCallback(async (searchQuery, pageNum = 1) => {
    if (searchQuery.length < 2) {
      setResults([]);
      setHasMore(true);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(API_ENDPOINTS.SEARCH_FOOD, {
        params: { query: searchQuery, page: pageNum, per_page: itemsPerPage },
        withCredentials: true  // Add this line
      });
      
      if (response.data && Array.isArray(response.data.results)) {
        if (pageNum === 1) {
          setResults(response.data.results);
        } else {
          setResults(prevResults => [...prevResults, ...response.data.results]);
        }
        setHasMore(response.data.results.length === itemsPerPage);
      }
    } catch (error) {
      console.error('Error searching for food:', error);
    }
    setLoading(false);
  }, []);

  const handleSearch = (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    setPage(1);
    searchFood(newQuery, 1);
  };

  const handleLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    searchFood(query, nextPage);
  };

  const handleSelect = (foodId, foodName) => {
    onFoodSelect(foodId, foodName);
    setQuery('');
    setResults([]);
    setPage(1);
    setHasMore(true);
  };

  const Row = ({ index, style }) => {
    const [foodId, foodName] = results[index];
    return (
      <ListItem style={style} key={foodId} button onClick={() => handleSelect(foodId, foodName)}>
        <ListItemText primary={foodName} secondary={`ID: ${foodId}`} />
      </ListItem>
    );
  };

  return (
    <div>
      <TextField
        fullWidth
        label="Search for a food"
        value={query}
        onChange={handleSearch}
        margin="normal"
      />
      {loading && <CircularProgress />}
      {results.length > 0 ? (
        <>
          <FixedSizeList
            height={400}
            width="100%"
            itemSize={50}
            itemCount={results.length}
            overscanCount={5}
          >
            {Row}
          </FixedSizeList>
          {hasMore && (
            <Button fullWidth onClick={handleLoadMore} disabled={loading}>
              Load More
            </Button>
          )}
        </>
      ) : (
        query.length > 1 && !loading && (
          <Typography color="textSecondary">No results found</Typography>
        )
      )}
    </div>
  );
};

export default FoodSearch;