import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { 
  TextField, Button, Typography, Paper, Grid, Snackbar,
  CircularProgress, List, ListItem, ListItemText, Box, Chip, Tooltip,
  Card, CardContent, Divider, Container, IconButton, Stepper, Step, StepLabel,
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CalculateIcon from '@mui/icons-material/Calculate';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ThumbUp, ThumbDown, BarChart, LocalDining, Nature, AttachMoney } from '@mui/icons-material';
import { API_ENDPOINTS } from '../config';
import FoodSearch from './FoodSearch';
import { Helmet } from 'react-helmet';

// Helper function to get cookie value
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

// Result Item Component
const ResultItem = ({ label, value, icon, tooltip }) => (
  <Box display="flex" alignItems="center" mb={2}>
    <Tooltip title={tooltip}>
      <Box mr={2}>{icon}</Box>
    </Tooltip>
    <Box flexGrow={1}>
      <Typography variant="body2" color="textSecondary">{label}</Typography>
      <Typography variant="h6">{value}</Typography>
    </Box>
  </Box>
);

ResultItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  tooltip: PropTypes.string.isRequired,
};

// Progress Bar Component
const ProgressBar = ({ value, minValue, maxValue, color }) => {
  const normalizedValue = ((value - minValue) / (maxValue - minValue)) * 100;
  return (
    <Box width="100%" height={8} bgcolor="grey.300" borderRadius={4} mt={1}>
      <Box
        width={`${normalizedValue}%`}
        height="100%"
        bgcolor={color}
        borderRadius={4}
      />
    </Box>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

// Net Health Impact Results Component
const NetHealthImpactResults = ({ result }) => {
  const interpretNetImpact = useMemo(() => (impact) => {
    if (impact > 60) return { text: "Very Positive", color: "success", icon: <ThumbUp color="success" /> };
    if (impact > 30) return { text: "Positive", color: "success", icon: <ThumbUp color="success" /> };
    if (impact > -30) return { text: "Neutral", color: "info", icon: <BarChart color="info" /> };
    if (impact > -60) return { text: "Negative", color: "warning", icon: <ThumbDown color="warning" /> };
    return { text: "Very Negative", color: "error", icon: <ThumbDown color="error" /> };
  }, []);

  const impactInterpretation = interpretNetImpact(result.net_health_impact);

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" gutterBottom>Results</Typography>
        <Box mb={3}>
          <Typography variant="h4" color="primary" gutterBottom>
            Net Health Impact: {result.net_health_impact?.toFixed(2) || 'N/A'} minutes of healthy life
            <Chip
              icon={impactInterpretation.icon}
              label={impactInterpretation.text}
              color={impactInterpretation.color}
              size="small"
              sx={{ ml: 2 }}
            />
          </Typography>
          <ProgressBar
            value={result.net_health_impact || 0}
            minValue={-100}
            maxValue={100}
            color={impactInterpretation.color}
          />
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <ResultItem
              label="HENI Score"
              value={`${result.heni_score?.toFixed(2) || 'N/A'} μDALYs per 100 kcal`}
              icon={<LocalDining color="primary" />}
              tooltip="Health Nutritional Index Score"
            />
            <ProgressBar
              value={result.heni_score || 0}
              minValue={-50}
              maxValue={50}
              color="primary"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ResultItem
              label="Total Calories"
              value={`${result.total_kcal.toFixed(2)} kcal`}
              icon={<LocalDining />}
              tooltip="Total caloric content of the meal"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ResultItem
              label="Total HENI"
              value={`${result.total_heni.toFixed(2)} μDALYs`}
              icon={<BarChart color="secondary" />}
              tooltip="Total Health Nutritional Index impact"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ResultItem
              label="Environmental Impact"
              value={`${result.environmental_impact_dalys.toFixed(6)} DALYs`}
              icon={<Nature color="error" />}
              tooltip="Environmental impact in Disability-Adjusted Life Years"
            />
            <ProgressBar
              value={result.environmental_impact_dalys}
              minValue={0}
              maxValue={0.001}
              color="error"
            />
          </Grid>
          <Grid item xs={12}>
            <ResultItem
              label="Total Monetized Environmental Impact"
              value={`$${result.total_monetized_impact.toFixed(2)}`}
              icon={<AttachMoney color="error" />}
              tooltip="Estimated economic cost of the environmental impact"
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />
        
        <Typography variant="body2" color="textSecondary">
          The Net Health Impact combines nutritional benefits (HENI) and environmental impacts on health.
          It's measured in minutes of healthy life gained or lost, where approximately 2 minutes correspond to 1 μDALY.
          This score considers both positive factors (e.g., nutrients from seafood, calcium, nuts) and negative factors (e.g., saturated fats, processed meats, environmental impacts).
          A positive value indicates a net positive impact on health, while a negative value suggests a net negative impact.
          The magnitude reflects the strength of the impact.
        </Typography>
      </CardContent>
    </Card>
  );
};

NetHealthImpactResults.propTypes = {
  result: PropTypes.shape({
    net_health_impact: PropTypes.number.isRequired,
    heni_score: PropTypes.number.isRequired,
    total_kcal: PropTypes.number.isRequired,
    total_heni: PropTypes.number.isRequired,
    environmental_impact_dalys: PropTypes.number.isRequired,
    total_monetized_impact: PropTypes.number.isRequired,
  }).isRequired,
};

// Main Net Health Impact Calculator Component
const NetHealthImpactCalculator = () => {
  const [foods, setFoods] = useState([]);
  const [currentFood, setCurrentFood] = useState({ food_id: '', name: '', amount: '', unit: 'g' });
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [seoMetadata, setSeoMetadata] = useState({
    title: "Net Health Impact Calculator | DISH Research",
    description: "Calculate the combined health and environmental impact of your meals. Get a holistic view of your food choices' effects on personal and planetary health.",
    keywords: "net health impact, environmental impact, health impact, sustainable eating, holistic food analysis"
  });

  useEffect(() => {
    // Read SEO metadata from cookies set by the backend
    const cookieTitle = getCookie('seo_title');
    const cookieDescription = getCookie('seo_description');
    const cookieKeywords = getCookie('seo_keywords');

    if (cookieTitle && cookieDescription && cookieKeywords) {
      setSeoMetadata({
        title: cookieTitle,
        description: cookieDescription,
        keywords: cookieKeywords
      });
    }
  }, []);

  const handleFoodSelect = useCallback((foodId, foodName) => {
    setCurrentFood(prev => ({ ...prev, food_id: foodId, name: foodName }));
  }, []);

  const handleAmountChange = useCallback((event) => {
    setCurrentFood(prev => ({ ...prev, amount: event.target.value }));
  }, []);

  const handleUnitChange = useCallback((event) => {
    setCurrentFood(prev => ({ ...prev, unit: event.target.value }));
  }, []);

  const handleAddFood = useCallback(() => {
    if (currentFood.food_id && currentFood.amount) {
      setFoods(prevFoods => [...prevFoods, currentFood]);
      setCurrentFood({ food_id: '', name: '', amount: '', unit: 'g' });
    } else {
      setError('Please select a food and specify an amount before adding.');
    }
  }, [currentFood]);

  const handleRemoveFood = useCallback((index) => {
    setFoods(prevFoods => prevFoods.filter((_, i) => i !== index));
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (foods.length === 0) {
      setError('Please add at least one food item before calculating.');
      return;
    }
    setLoading(true);
    setError('');
    try {
      const response = await axios.post(API_ENDPOINTS.NET_HEALTH_IMPACT, {
        meal: foods.map(food => ({
          food_id: food.food_id,
          amount: parseFloat(food.amount),
          unit: food.unit
        }))
      });
      console.log('API Response:', response.data);  // Log the entire response
      if (response.data && response.data.data) {
        setResult(response.data.data);
        // Update SEO metadata if available
        if (response.data.seo_metadata) {
          setSeoMetadata(response.data.seo_metadata);
        }
      } else {
        console.error('Unexpected API response structure:', response.data);
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error calculating Net Health Impact:', error);
      setError(error.response?.data?.error || 'Failed to calculate Net Health Impact. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [foods]);

  const activeStep = useMemo(() => {
    if (result) return 2;
    if (foods.length > 0) return 1;
    return 0;
  }, [foods, result]);

  return (
    <Container maxWidth="md">
      <Helmet>
        <title>{seoMetadata.title}</title>
        <meta name="description" content={seoMetadata.description} />
        <meta name="keywords" content={seoMetadata.keywords} />
      </Helmet>
      <Paper elevation={3} sx={{ padding: 3, mt: 4 }}>
        <Typography variant="h4" gutterBottom align="center">Net Health Impact Calculator</Typography>
        <Typography variant="body2" paragraph align="center">
          Estimate the overall effect on healthy life minutes by combining nutritional benefits (HENI) and environmental impacts.
        </Typography>

        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          <Step><StepLabel>Add Foods</StepLabel></Step>
          <Step><StepLabel>Review Meal</StepLabel></Step>
          <Step><StepLabel>View Results</StepLabel></Step>
        </Stepper>
        
        <Card variant="outlined" sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>Add Foods to Your Meal</Typography>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={12}>
                <FoodSearch onFoodSelect={handleFoodSelect} />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Amount"
                  type="number"
                  value={currentFood.amount}
                  onChange={handleAmountChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Unit</InputLabel>
                  <Select
                    value={currentFood.unit}
                    onChange={handleUnitChange}
                    label="Unit"
                  >
                    <MenuItem value="g">g</MenuItem>
                    <MenuItem value="ml">ml</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={handleAddFood}
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={!currentFood.food_id || !currentFood.amount}
                  startIcon={<AddIcon />}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
            
            <List sx={{ mt: 2 }}>
              {foods.map((food, index) => (
                <ListItem key={index} sx={{ py: 1, bgcolor: index % 2 === 0 ? 'background.default' : 'background.paper' }}>
                  <ListItemText 
                    primary={food.name} 
                    secondary={`${food.amount} ${food.unit}`} 
                    sx={{ flexGrow: 1 }}
                  />
                  <IconButton onClick={() => handleRemoveFood(index)} size="small">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>

        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <Button 
            onClick={handleSubmit} 
            variant="contained" 
            color="primary"
            disabled={loading || foods.length === 0}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <CalculateIcon />}
            size="large"
          >
            {loading ? 'Calculating...' : 'Calculate Net Health Impact'}
          </Button>
        </Box>

        {result && <NetHealthImpactResults result={result} />}

        <Tooltip title="Learn more about Net Health Impact">
          <IconButton 
            color="primary" 
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            onClick={() => window.open('https://www.nature.com/articles/s43016-021-00343-4', '_blank')}
          >
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>

        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
          <Alert onClose={() => setError('')} severity="error" variant="filled">
            {error}
          </Alert>
        </Snackbar>
      </Paper>
    </Container>
  );
};

export default React.memo(NetHealthImpactCalculator);

// PropTypes for FoodSearch component (assumed to exist)
FoodSearch.propTypes = {
  onFoodSelect: PropTypes.func.isRequired,
};