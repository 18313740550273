import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button, FormControl, InputLabel, Select, MenuItem, TextField, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';

const NutrientValuesStep = ({ 
  nutrientValues, onChange, onAdd, onRemove, nutrients, nutrientSources, addNutrientSource, errors 
}) => {
  const [isAddSourceDialogOpen, setIsAddSourceDialogOpen] = useState(false);
  const [newSourceDescription, setNewSourceDescription] = useState('');
  const [currentNutrientIndex, setCurrentNutrientIndex] = useState(null);
  const [isAddingSource, setIsAddingSource] = useState(false);

  const handleAddSource = async () => {
    if (newSourceDescription.trim()) {
      try {
        setIsAddingSource(true);
        const newSource = await addNutrientSource(newSourceDescription);
        if (currentNutrientIndex !== null && newSource) {
          onChange(currentNutrientIndex, 'NutrientSourceID', newSource.NutrientSourceID);
        }
        setIsAddSourceDialogOpen(false);
        setNewSourceDescription('');
        setCurrentNutrientIndex(null);
      } catch (error) {
        console.error('Failed to add nutrient source:', error);
      } finally {
        setIsAddingSource(false);
      }
    }
  };

  const handleSourceChange = (index, value) => {
    if (value === 'add_new') {
      setCurrentNutrientIndex(index);
      setIsAddSourceDialogOpen(true);
    } else {
      onChange(index, 'NutrientSourceID', value);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Nutrient Values</Typography>
      {nutrientValues.map((nutrient, index) => (
        <Box key={index} display="flex" alignItems="center" mb={2}>
          <FormControl fullWidth>
            <InputLabel>Nutrient</InputLabel>
            <Select
              value={nutrient.NutrientID || ''}
              onChange={(e) => onChange(index, 'NutrientID', e.target.value)}
              required
            >
              {nutrients.map((n) => (
                <MenuItem key={n.NutrientID} value={n.NutrientID}>
                  {n.NutrientName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Value"
            type="number"
            value={nutrient.NutrientValue || ''}
            onChange={(e) => onChange(index, 'NutrientValue', parseFloat(e.target.value))}
            fullWidth
            inputProps={{ step: "0.01", min: "0" }}
            required
          />
          <FormControl fullWidth>
            <InputLabel>Nutrient Source</InputLabel>
            <Select
              value={nutrient.NutrientSourceID || ''}
              onChange={(e) => handleSourceChange(index, e.target.value)}
              required
            >
              {nutrientSources.map((source) => (
                <MenuItem key={source.NutrientSourceID} value={source.NutrientSourceID}>
                  {source.NutrientSourceDescription}
                </MenuItem>
              ))}
              <MenuItem value="add_new">
                <em>Add New Source</em>
              </MenuItem>
            </Select>
          </FormControl>
          <Button 
            onClick={() => onRemove(index)} 
            color="error" 
            variant="outlined" 
            sx={{ ml: 2, minWidth: 100 }}
          >
            Remove
          </Button>
        </Box>
      ))}
      <Button 
        onClick={() => onAdd({ NutrientID: '', NutrientValue: '', NutrientSourceID: '' })} 
        variant="contained" 
        color="primary" 
        sx={{ mt: 2 }}
      >
        Add Nutrient
      </Button>

      <Dialog open={isAddSourceDialogOpen} onClose={() => !isAddingSource && setIsAddSourceDialogOpen(false)}>
        <DialogTitle>Add New Nutrient Source</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="sourceDescription"
            label="Source Description"
            type="text"
            fullWidth
            value={newSourceDescription}
            onChange={(e) => setNewSourceDescription(e.target.value)}
            disabled={isAddingSource}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddSourceDialogOpen(false)} disabled={isAddingSource}>Cancel</Button>
          <Button 
            onClick={handleAddSource} 
            color="primary" 
            disabled={!newSourceDescription.trim() || isAddingSource}
          >
            {isAddingSource ? <CircularProgress size={24} /> : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

NutrientValuesStep.propTypes = {
  nutrientValues: PropTypes.arrayOf(PropTypes.shape({
    NutrientID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    NutrientValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    NutrientSourceID: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  nutrients: PropTypes.arrayOf(PropTypes.shape({
    NutrientID: PropTypes.number.isRequired,
    NutrientName: PropTypes.string.isRequired
  })).isRequired,
  nutrientSources: PropTypes.arrayOf(PropTypes.shape({
    NutrientSourceID: PropTypes.number.isRequired,
    NutrientSourceDescription: PropTypes.string.isRequired
  })).isRequired,
  addNutrientSource: PropTypes.func.isRequired,
};

export default NutrientValuesStep;