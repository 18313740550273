import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { 
  TextField, Button, Typography, Paper, Grid, Snackbar,
  CircularProgress, List, ListItem, ListItemText, Box, Chip, Tooltip,
  Card, CardContent, Divider, Container, IconButton, Stepper, Step, StepLabel,
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import CalculateIcon from '@mui/icons-material/Calculate';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { API_ENDPOINTS } from '../config';
import FoodSearch from './FoodSearch';
import { Helmet } from 'react-helmet';

// Helper function to get cookie value
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const HENICalculator = () => {
  const [foods, setFoods] = useState([]);
  const [currentFood, setCurrentFood] = useState({ food_id: '', name: '', amount: '', unit: 'g' });
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [seoMetadata, setSeoMetadata] = useState({
    title: "Health and Nutritional Impact (HENI) Calculator | DISH Research",
    description: "Calculate the Health and Nutritional Impact (HENI) score for your meals. Analyze the health benefits of your food choices.",
    keywords: "HENI, health impact, nutritional impact, meal analysis, healthy eating"
  });

  useEffect(() => {
    // Read SEO metadata from cookies set by the backend
    const cookieTitle = getCookie('seo_title');
    const cookieDescription = getCookie('seo_description');
    const cookieKeywords = getCookie('seo_keywords');

    if (cookieTitle && cookieDescription && cookieKeywords) {
      setSeoMetadata({
        title: cookieTitle,
        description: cookieDescription,
        keywords: cookieKeywords
      });
    }
  }, []);

  const handleFoodSelect = useCallback((foodId, foodName) => {
    setCurrentFood(prev => ({ ...prev, food_id: foodId, name: foodName }));
  }, []);

  const handleAmountChange = useCallback((event) => {
    setCurrentFood(prev => ({ ...prev, amount: event.target.value }));
  }, []);

  const handleUnitChange = useCallback((event) => {
    setCurrentFood(prev => ({ ...prev, unit: event.target.value }));
  }, []);

  const handleAddFood = useCallback(() => {
    if (currentFood.food_id && currentFood.amount) {
      setFoods(prevFoods => [...prevFoods, currentFood]);
      setCurrentFood({ food_id: '', name: '', amount: '', unit: 'g' });
    } else {
      setError('Please select a food and specify an amount before adding.');
    }
  }, [currentFood]);

  const handleRemoveFood = useCallback((index) => {
    setFoods(prevFoods => prevFoods.filter((_, i) => i !== index));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (foods.length === 0) {
      setError('Please add at least one food item before calculating.');
      return;
    }
    setLoading(true);
    setError('');
    try {
      console.log('Sending meal data:', foods);
      const response = await axios.post(API_ENDPOINTS.HENI_CALCULATE, {
        meal: foods.map(food => ({
          food_id: food.food_id,
          amount: parseFloat(food.amount),
          unit: food.unit
        }))
      });
      console.log('Raw API Response:', response);
      console.log('API Response data:', response.data);
  
      if (response.data && response.data.data && response.data.data.data) {
        const { heni_score, total_kcal, total_heni, ingredient_categories } = response.data.data.data;
        setResult({
          heni_score: typeof heni_score === 'number' ? heni_score : 'N/A',
          total_kcal: typeof total_kcal === 'number' ? total_kcal : 'N/A',
          total_heni: typeof total_heni === 'number' ? total_heni : 'N/A',
          ingredient_categories: ingredient_categories || {}
        });
        // Update SEO metadata if available
        if (response.data.seo_metadata) {
          setSeoMetadata(response.data.seo_metadata);
        }
      } else {
        console.error('Unexpected API response structure:', response.data);
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error calculating HENI:', error);
      setError(error.response?.data?.error || 'Failed to calculate HENI. Please try again.');
    } finally {
      setLoading(false);
    }
  }; 

  const interpretHENI = useMemo(() => (score) => {
    if (score <= -50) return { text: "Excellent", color: "success", description: "Very positive health effects" };
    if (score <= -25) return { text: "Very Good", color: "success", description: "Positive health effects" };
    if (score <= 0) return { text: "Good", color: "info", description: "Slightly positive health effects" };
    if (score <= 25) return { text: "Fair", color: "warning", description: "Slightly negative health effects" };
    return { text: "Poor", color: "error", description: "Negative health effects" };
  }, []);

  const activeStep = useMemo(() => {
    if (result) return 2;
    if (foods.length > 0) return 1;
    return 0;
  }, [foods, result]);

  const IngredientCategories = ({ categories }) => {
    return (
      <div>
        <Typography variant="subtitle1" gutterBottom>Nutrients Highlight:</Typography>
        {Object.entries(categories).map(([foodId, foodCategories]) => (
          <div key={foodId}>
            <Typography variant="subtitle2">{foodCategories.name || `Food ID: ${foodId}`}</Typography>
            <Grid container spacing={1}>
              {Object.entries(foodCategories)
                .filter(([key]) => key !== 'name')
                .map(([category, value]) => (
                  <Grid item xs={6} sm={4} key={category}>
                    <Typography variant="body2">
                      {category}: {typeof value === 'number' ? value.toFixed(2) : value}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Container maxWidth="md">
      <Helmet>
        <title>{seoMetadata.title}</title>
        <meta name="description" content={seoMetadata.description} />
        <meta name="keywords" content={seoMetadata.keywords} />
      </Helmet>
      <Paper elevation={3} sx={{ padding: 3, mt: 4 }}>
        <Typography variant="h4" gutterBottom align="center">Health Nutritional Index (HENI) Calculator</Typography>
        <Typography variant="body2" paragraph align="center">
          Calculate the HENI score to estimate the health impact of your meal in microDALYs (μDALYs) per 100 kcal.
        </Typography>

        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          <Step><StepLabel>Add Foods</StepLabel></Step>
          <Step><StepLabel>Review Meal</StepLabel></Step>
          <Step><StepLabel>View Results</StepLabel></Step>
        </Stepper>
        
        <Card variant="outlined" sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>Add Foods to Your Meal</Typography>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={12}>
                <FoodSearch onFoodSelect={handleFoodSelect} />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Amount"
                  type="number"
                  value={currentFood.amount}
                  onChange={handleAmountChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Unit</InputLabel>
                  <Select
                    value={currentFood.unit}
                    onChange={handleUnitChange}
                    label="Unit"
                  >
                    <MenuItem value="g">g</MenuItem>
                    <MenuItem value="ml">ml</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={handleAddFood}
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={!currentFood.food_id || !currentFood.amount}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
            
            <List sx={{ mt: 2 }}>
              {foods.map((food, index) => (
                <ListItem key={index} sx={{ py: 1, bgcolor: index % 2 === 0 ? 'background.default' : 'background.paper' }}>
                  <ListItemText 
                    primary={food.name} 
                    secondary={`${food.amount} ${food.unit}`} 
                    sx={{ flexGrow: 1 }}
                  />
                  <IconButton onClick={() => handleRemoveFood(index)} size="small">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>

        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <Button 
            onClick={handleSubmit} 
            variant="contained" 
            color="primary"
            disabled={loading || foods.length === 0}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <CalculateIcon />}
            size="large"
          >
            {loading ? 'Calculating...' : 'Calculate HENI'}
          </Button>
        </Box>

        {result && (
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>Results</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" color="primary">
                  HENI Score: {result.heni_score !== 'N/A' ? result.heni_score.toFixed(2) : 'N/A'} μDALYs per 100 kcal
                  <Tooltip title={interpretHENI(result.heni_score !== 'N/A' ? result.heni_score : 0).description}>
                    <Chip 
                      label={interpretHENI(result.heni_score !== 'N/A' ? result.heni_score : 0).text} 
                      color={interpretHENI(result.heni_score !== 'N/A' ? result.heni_score : 0).color}
                      size="small"
                      sx={{ ml: 1 }}
                      icon={<InfoIcon />}
                    />
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  Total Calories: {result.total_kcal !== 'N/A' ? result.total_kcal.toFixed(2) : 'N/A'} kcal
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  Total HENI: {result.total_heni !== 'N/A' ? result.total_heni.toFixed(2) : 'N/A'} μDALYs
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <IngredientCategories categories={result.ingredient_categories} />
              <Divider sx={{ my: 2 }} />
              <Typography variant="body2">
                The HENI score represents the health impact in microDALYs (μDALYs) per 100 kcal. 
                A negative score indicates a positive health effect, while a positive score indicates a negative health effect.
                The score considers various nutritional factors and their impact on health outcomes.
              </Typography>
            </CardContent>
          </Card>
        )}

        <Tooltip title="Learn more about HENI">
          <IconButton 
            color="primary" 
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            onClick={() => window.open('https://www.nature.com/articles/s43016-021-00343-4', '_blank')}
          >
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>

        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
          <Alert onClose={() => setError('')} severity="error" variant="filled">
            {error}
          </Alert>
        </Snackbar>
      </Paper>
    </Container>
  );
};

HENICalculator.propTypes = {
  // Add any props if needed
};

export default React.memo(HENICalculator);

// PropTypes for FoodSearch component (assumed to exist)
FoodSearch.propTypes = {
  onFoodSelect: PropTypes.func.isRequired,
};