import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from '../../config';

const useFoodData = () => {
  const [selectedFood, setSelectedFood] = useState(null);
  const [isAddFoodDialogOpen, setIsAddFoodDialogOpen] = useState(false);
  const [isEditFoodDialogOpen, setIsEditFoodDialogOpen] = useState(false);
  const [isBatchAddDialogOpen, setIsBatchAddDialogOpen] = useState(false);
  const [newFoodData, setNewFoodData] = useState({
    FoodDescription: '',
    FoodDescriptionF: '',
    FoodGroupIDs: [],
    FoodSourceID: '',
    CountryCode: '',
    ScientificName: '',
    NutrientValues: [],
    ConversionFactors: []
  });
  const [batchFoodData, setBatchFoodData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [foodGroups, setFoodGroups] = useState([]);
  const [foodSources, setFoodSources] = useState([]);
  const [nutrients, setNutrients] = useState([]);
  const [measures, setMeasures] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [nutrientSources, setNutrientSources] = useState([]);

  const fetchData = useCallback(async (endpoint, setStateFunction, errorMessage) => {
    try {
      const response = await axios.get(endpoint);
      setStateFunction(response.data);
    } catch (error) {
      console.error(`Error fetching ${errorMessage}:`, error);
      setError(`Failed to fetch ${errorMessage}. Please try again.`);
    }
  }, []);

  const fetchFoodGroups = useCallback(() => fetchData(API_ENDPOINTS.CNF_FOOD_GROUPS, setFoodGroups, 'food groups'), [fetchData]);
  const fetchFoodSources = useCallback(() => fetchData(API_ENDPOINTS.CNF_FOOD_SOURCES, setFoodSources, 'food sources'), [fetchData]);
  const fetchNutrients = useCallback(() => fetchData(API_ENDPOINTS.CNF_NUTRIENTS, setNutrients, 'nutrients'), [fetchData]);
  const fetchMeasures = useCallback(() => fetchData(API_ENDPOINTS.CNF_MEASURES, setMeasures, 'measures'), [fetchData]);

  const fetchNutrientSources = useCallback(() => fetchData(API_ENDPOINTS.CNF_NUTRIENT_SOURCES, setNutrientSources, 'nutrient sources'), [fetchData]);

  useEffect(() => {
    fetchFoodGroups();
    fetchFoodSources();
    fetchNutrients();
    fetchMeasures();
    fetchNutrientSources();
  }, [fetchFoodGroups, fetchFoodSources, fetchNutrients, fetchMeasures, fetchNutrientSources]);

  const handleFoodSelect = useCallback(async (foodId) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${API_ENDPOINTS.CNF_GET_FOOD}${foodId}/`);
      setSelectedFood(response.data);
    } catch (error) {
      console.error('Error fetching food details:', error);
      if (error.response) {
        if (error.response.status === 404) {
          setError('Food item not found. It may have been removed from the database.');
        } else {
          setError(`Server error: ${error.response.data.error || 'An unexpected error occurred'}`);
        }
      } else if (error.request) {
        setError('No response received from the server. Please check your internet connection and try again.');
      } else {
        setError('An unexpected error occurred while fetching food details. Please try again later.');
      }
      setSelectedFood(null);
    }
    setIsLoading(false);
  }, []);

  const refreshSearchResults = useCallback(async (query) => {
    if (!query) return;
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_ENDPOINTS.CNF_SEARCH_FOODS}?q=${query}`);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error refreshing search results:', error);
      setError('Failed to refresh search results. Please try again.');
    }
    setIsLoading(false);
  }, []);

  const handleAddFood = useCallback(async (foodData) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(API_ENDPOINTS.CNF_ADD_FOOD, foodData);
      console.log(response.data.message);
      setIsAddFoodDialogOpen(false);
      refreshSearchResults(foodData.FoodDescription);
      return response.data;
    } catch (error) {
      console.error('Error adding new food:', error);
      setError('Failed to add new food. Please try again.');
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [refreshSearchResults]);

  const handleEditFood = useCallback(async (foodData) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.put(`${API_ENDPOINTS.CNF_GET_FOOD}${foodData.FoodID}/`, foodData);
      console.log(response.data.message);
      setIsEditFoodDialogOpen(false);
      setSelectedFood(response.data);
      await refreshSearchResults(foodData.FoodDescription);
      return response.data;
    } catch (error) {
      console.error('Error editing food:', error);
      setError('Failed to edit food. Please try again.');
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [refreshSearchResults]);

  const handleAddFoodsBatch = useCallback(async (foodDataArray) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(API_ENDPOINTS.CNF_ADD_FOODS_BATCH, foodDataArray);
      console.log(response.data.message);
      setIsBatchAddDialogOpen(false);
      refreshSearchResults(foodDataArray[0].FoodDescription);
      return response.data;
    } catch (error) {
      console.error('Error adding foods in batch:', error);
      setError('Failed to add foods in batch. Please try again.');
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [refreshSearchResults]);

  const checkDataIntegrity = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(API_ENDPOINTS.CNF_CHECK_DATA_INTEGRITY);
      console.log(response.data.message);
      return response.data;
    } catch (error) {
      console.error('Error checking data integrity:', error);
      setError('Failed to check data integrity. Please try again.');
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const addFoodSource = useCallback(async (description) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(API_ENDPOINTS.CNF_ADD_FOOD_SOURCE, { description });
      setFoodSources(prevSources => [...prevSources, response.data]);
      return response.data;
    } catch (error) {
      console.error('Error adding new food source:', error);
      setError('Failed to add new food source. Please try again.');
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const addNutrientSource = useCallback(async (description) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(API_ENDPOINTS.CNF_ADD_NUTRIENT_SOURCE, { description });
      setNutrientSources(prevSources => [...prevSources, response.data]);
      return response.data;
    } catch (error) {
      console.error('Error adding new nutrient source:', error);
      setError('Failed to add new nutrient source. Please try again.');
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    selectedFood,
    isLoading,
    error,
    handleFoodSelect,
    isAddFoodDialogOpen,
    setIsAddFoodDialogOpen,
    isEditFoodDialogOpen,
    setIsEditFoodDialogOpen,
    isBatchAddDialogOpen,
    setIsBatchAddDialogOpen,
    handleAddFood,
    handleEditFood,
    handleAddFoodsBatch,
    checkDataIntegrity,
    newFoodData,
    setNewFoodData,
    batchFoodData,
    setBatchFoodData,
    foodGroups,
    foodSources,
    nutrients,
    measures,
    refreshSearchResults,
    searchResults,
    nutrientSources,
    addNutrientSource,
    addFoodSource,
  };
};

export default useFoodData;