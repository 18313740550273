import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  Typography, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  TextField, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  CircularProgress
} from '@mui/material';
import axios from 'axios';
import { API_ENDPOINTS } from '../config';

const ConversionFactorsStep = ({ 
  conversionFactors, onChange, onAdd, onRemove, measures, errors 
}) => {
  const [isNewMeasureDialogOpen, setIsNewMeasureDialogOpen] = useState(false);
  const [newMeasureDescription, setNewMeasureDescription] = useState('');
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isAddingMeasure, setIsAddingMeasure] = useState(false);

  const handleAddNewMeasure = async () => {
    if (newMeasureDescription.trim()) {
      setIsAddingMeasure(true);
      try {
        const response = await axios.post(API_ENDPOINTS.CNF_ADD_MEASURE, { description: newMeasureDescription });
        const newMeasure = response.data;
        
        // Update the measures list with the new measure
        measures.push(newMeasure);
        
        // Assign the newly created measure to the current conversion factor
        onChange(currentIndex, 'MeasureID', newMeasure.MeasureID);

        // Reset the dialog state
        setIsNewMeasureDialogOpen(false);
        setNewMeasureDescription('');
      } catch (error) {
        console.error('Error adding new measure:', error);
      } finally {
        setIsAddingMeasure(false);
      }
    }
  };

  const handleMeasureChange = (index, value) => {
    if (value === 'new') {
      setIsNewMeasureDialogOpen(true);
      setCurrentIndex(index);
    } else {
      onChange(index, 'MeasureID', value);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Conversion Factors</Typography>
      {conversionFactors.map((factor, index) => (
        <Box key={index} display="flex" alignItems="center" mb={2}>
          <FormControl fullWidth error={!!errors?.[index]?.MeasureID}>
            <InputLabel>Measure Description</InputLabel>
            <Select
              value={factor.MeasureID || ''}
              onChange={(e) => handleMeasureChange(index, e.target.value)}
              required
            >
              {measures.map((measure) => (
                <MenuItem key={measure.MeasureID} value={measure.MeasureID}>
                  {measure.MeasureDescription}
                </MenuItem>
              ))}
              <MenuItem value="new">
                <em>Add New Measure</em>
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Conversion Factor"
            type="number"
            value={factor.ConversionFactorValue || ''}
            onChange={(e) => onChange(index, 'ConversionFactorValue', parseFloat(e.target.value))}
            fullWidth
            error={!!errors?.[index]?.ConversionFactorValue}
            helperText={errors?.[index]?.ConversionFactorValue}
            inputProps={{ step: "0.01", min: "0" }}
            required
          />
          <Button 
            onClick={() => onRemove(index)} 
            color="error" 
            variant="outlined" 
            sx={{ ml: 2, minWidth: 100 }}
            aria-label={`Remove Conversion Factor ${index + 1}`}
          >
            Remove
          </Button>
        </Box>
      ))}
      <Button 
        onClick={() => onAdd({ MeasureID: '', ConversionFactorValue: '' })} 
        variant="contained" 
        color="primary" 
        sx={{ mt: 2 }}
        aria-label="Add Conversion Factor"
      >
        Add Conversion Factor
      </Button>
      {errors.general && <Typography color="error" sx={{ mt: 2 }}>{errors.general}</Typography>}

      <Dialog open={isNewMeasureDialogOpen} onClose={() => !isAddingMeasure && setIsNewMeasureDialogOpen(false)}>
        <DialogTitle>Add New Measure</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Measure Description"
            fullWidth
            value={newMeasureDescription}
            onChange={(e) => setNewMeasureDescription(e.target.value)}
            inputProps={{ 'aria-label': 'New Measure Description' }}
            disabled={isAddingMeasure}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNewMeasureDialogOpen(false)} disabled={isAddingMeasure}>Cancel</Button>
          <Button 
            onClick={handleAddNewMeasure} 
            color="primary" 
            disabled={!newMeasureDescription.trim() || isAddingMeasure}
          >
            {isAddingMeasure ? <CircularProgress size={24} /> : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

ConversionFactorsStep.propTypes = {
  conversionFactors: PropTypes.arrayOf(PropTypes.shape({
    MeasureID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ConversionFactorValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  measures: PropTypes.arrayOf(PropTypes.shape({
    MeasureID: PropTypes.number.isRequired,
    MeasureDescription: PropTypes.string.isRequired
  })).isRequired,
  errors: PropTypes.object
};

export default ConversionFactorsStep;