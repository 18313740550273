const API_BASE_URL = 'https://api-research.ecodish365.com';

export const API_ENDPOINTS = {
    // Existing endpoints
    ENVIRONMENTAL_IMPACT: `${API_BASE_URL}/environmental-impact/`,
    FCS_CALCULATE: `${API_BASE_URL}/fcs-calculate/`,
    HENI_CALCULATE: `${API_BASE_URL}/heni-calculate/`,
    HSR_CALCULATE: `${API_BASE_URL}/hsr-calculate/`,
    SEARCH_FOOD: `${API_BASE_URL}/search-food/`, 
    NET_HEALTH_IMPACT: `${API_BASE_URL}/calculate-net-health-impact/`,
    
    // CNF endpoints
    CNF_ADD_FOOD: `${API_BASE_URL}/cnf/add-food-to-cnf/`,
    CNF_GET_FOOD: `${API_BASE_URL}/cnf/food/`,
    CNF_SEARCH_FOODS: `${API_BASE_URL}/cnf/search/`,
    CNF_FOOD_GROUPS: `${API_BASE_URL}/cnf/food-groups/`,
    CNF_FOOD_SOURCES: `${API_BASE_URL}/cnf/food-sources/`,
    CNF_NUTRIENTS: `${API_BASE_URL}/cnf/nutrients/`,
    CNF_NUTRIENT_SOURCES: `${API_BASE_URL}/cnf/nutrient-sources/`,
    CNF_MEASURES: `${API_BASE_URL}/cnf/measures/`,
    CNF_ADD_FOOD_SOURCE: `${API_BASE_URL}/cnf/add-food-source/`,
    CNF_ADD_MEASURE: `${API_BASE_URL}/cnf/add-new-measure/`,
    
    // Translation endpoints
    TRANSLATE: `${API_BASE_URL}/cnf/translate/`,
    // CHANGE_SOURCE_LANGUAGE: `${API_BASE_URL}/cnf/change-language/`,
    // GET_SUPPORTED_LANGUAGES: `${API_BASE_URL}/cnf/supported_languages/`,
    
    // New endpoints
    CNF_ADD_FOODS_BATCH: `${API_BASE_URL}/cnf/add-foods-batch/`,
    CNF_CHECK_DATA_INTEGRITY: `${API_BASE_URL}/cnf/check-data-integrity/`,
    
    // Endpoint for adding nutrient sources
    CNF_ADD_NUTRIENT_SOURCE: `${API_BASE_URL}/cnf/add-nutrient-source/`,
    
};