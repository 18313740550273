import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { 
  Grid, TextField, Button, FormControl, InputLabel, 
  Select, MenuItem, Typography, Chip, Autocomplete, CircularProgress,
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';

const BasicInfoStep = ({ 
  foodData, 
  onChange, 
  onTranslate, 
  foodGroups, 
  foodSources, 
  errors, 
  isLoading, 
  onCreateNewFoodSource,
}) => {
  const [isAddSourceDialogOpen, setIsAddSourceDialogOpen] = useState(false);
  const [newSourceDescription, setNewSourceDescription] = useState('');
  const [isAddingSource, setIsAddingSource] = useState(false);

  const handleAddNewSource = async () => {
    console.log('Adding new food source:', newSourceDescription);
    
    if (newSourceDescription.trim()) {
        try {
            setIsAddingSource(true);
            const newSource = await onCreateNewFoodSource(newSourceDescription.trim());
            console.log('New source created:', newSource);

            setIsAddSourceDialogOpen(false);
            setNewSourceDescription('');

            // Update the FoodSourceID with the newly created source
            if (newSource && newSource.FoodSourceID) {
                console.log('Setting new FoodSourceID:', newSource.FoodSourceID);
                onChange({
                    target: {
                        name: 'FoodSourceID',
                        value: newSource.FoodSourceID
                    }
                });

                // Log the final state
                console.log('Final food data state:', foodData);
            } else {
                console.error('No FoodSourceID returned from new source');
            }
        } catch (error) {
            console.error('Failed to add food source:', error);
        } finally {
            setIsAddingSource(false);
        }
    } else {
        console.warn('New source description is empty');
    }
  };


const handleFoodSourceChange = (event) => {
  const value = event.target.value;
  console.log('Food source selected:', value);

  if (value === 'add_new') {
      console.log('Opening dialog to add new source');
      setIsAddSourceDialogOpen(true);
  } else {
      onChange(event);
  }
};


  const safeFilterFoodGroups = () => {
    if (!foodGroups || !Array.isArray(foodGroups)) {
      return [];
    }
    return foodGroups.filter(fg => foodData.FoodGroupIDs.includes(fg.FoodGroupID));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          name="FoodDescription"
          label="Food Description (English)"
          fullWidth
          value={foodData.FoodDescription}
          onChange={onChange}
          error={!!errors.FoodDescription}
          helperText={errors.FoodDescription}
          required
          inputProps={{ 'aria-label': 'Food Description (English)' }}
        />
      </Grid>
      <Grid item xs={10}>
        <TextField
          name="FoodDescriptionF"
          label="Food Description (French)"
          fullWidth
          value={foodData.FoodDescriptionF}
          onChange={onChange}
          error={!!errors.FoodDescriptionF}
          helperText={errors.FoodDescriptionF}
          required
          inputProps={{ 'aria-label': 'Food Description (French)' }}
        />
      </Grid>
      <Grid item xs={2}>
        <Button 
          onClick={onTranslate} 
          disabled={!foodData.FoodDescription || isLoading}
          variant="contained"
          fullWidth
          sx={{ height: '100%' }}
          aria-label="Translate to French"
        >
          {isLoading ? <CircularProgress size={24} /> : 'Translate'}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          options={foodGroups || []}
          getOptionLabel={(option) => option.FoodGroupName}
          value={safeFilterFoodGroups()}
          onChange={(event, newValue) => {
            onChange({
              target: {
                name: 'FoodGroupIDs',
                value: newValue.map(v => v.FoodGroupID)
              }
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Food Groups"
              error={!!errors.FoodGroupIDs}
              helperText={errors.FoodGroupIDs}
              required
              inputProps={{
                ...params.inputProps,
                'aria-label': 'Select Food Groups'
              }}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip key={option.FoodGroupID} label={option.FoodGroupName} {...getTagProps({ index })} />
            ))
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth required error={!!errors.FoodSourceID}>
          <InputLabel id="food-source-label">Food Source</InputLabel>
          <Select
            labelId="food-source-label"
            name="FoodSourceID"
            value={foodData.FoodSourceID}
            onChange={handleFoodSourceChange}
            aria-label="Food Source"
          >
            {foodSources && foodSources.map((source) => (
              <MenuItem key={source.FoodSourceID} value={source.FoodSourceID}>
                {source.FoodSourceDescription}
              </MenuItem>
            ))}
            <MenuItem value="add_new">
              <em>Add New Source</em>
            </MenuItem>
          </Select>
          {errors.FoodSourceID && <Typography color="error">{errors.FoodSourceID}</Typography>}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="CountryCode"
          label="Country Code"
          fullWidth
          value={foodData.CountryCode}
          onChange={onChange}
          error={!!errors.CountryCode}
          helperText={errors.CountryCode}
          required
          inputProps={{ 'aria-label': 'Country Code', maxLength: 5 }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="ScientificName"
          label="Scientific Name"
          fullWidth
          value={foodData.ScientificName}
          onChange={onChange}
          inputProps={{ 'aria-label': 'Scientific Name' }}
        />
      </Grid>

      <Dialog open={isAddSourceDialogOpen} onClose={() => setIsAddSourceDialogOpen(false)}>
        <DialogTitle>Add New Food Source</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Source Description"
            fullWidth
            value={newSourceDescription}
            onChange={(e) => setNewSourceDescription(e.target.value)}
            inputProps={{ 'aria-label': 'New Source Description' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddSourceDialogOpen(false)} disabled={isAddingSource}>Cancel</Button>
          <Button 
            onClick={handleAddNewSource} 
            color="primary" 
            disabled={!newSourceDescription.trim() || isAddingSource}
          >
            {isAddingSource ? <CircularProgress size={24} /> : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

BasicInfoStep.propTypes = {
  foodData: PropTypes.shape({
    FoodDescription: PropTypes.string.isRequired,
    FoodDescriptionF: PropTypes.string.isRequired,
    FoodGroupIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    FoodSourceID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    CountryCode: PropTypes.string.isRequired,
    ScientificName: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onTranslate: PropTypes.func.isRequired,
  foodGroups: PropTypes.arrayOf(PropTypes.shape({
    FoodGroupID: PropTypes.number.isRequired,
    FoodGroupName: PropTypes.string.isRequired
  })),
  foodSources: PropTypes.arrayOf(PropTypes.shape({
    FoodSourceID: PropTypes.number.isRequired,
    FoodSourceDescription: PropTypes.string.isRequired
  })),
  errors: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCreateNewFoodSource: PropTypes.func.isRequired,
  newSourceDialogOpen: PropTypes.bool.isRequired,
  setNewSourceDialogOpen: PropTypes.func.isRequired
};

export default BasicInfoStep;