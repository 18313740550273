import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container, Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import EnvironmentalImpact from './components/EnvironmentalImpact';
import FCSCalculator from './components/FCSCalculator';
import HENICalculator from './components/HENICalculator';
import HSRCalculator from './components/HSRCalculator';
import NetHealthImpactCalculator from './components/NetHealthImpactCalculator';
import CNFDatabaseManager from './components/CNFDatabaseManager';
import HomePage from './components/HomePage';

// Helper function to get cookie value
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const Layout = ({ children, title, description }) => {
  const [seoMetadata, setSeoMetadata] = useState({
    title: title,
    description: description,
    keywords: ''
  });

  useEffect(() => {
    const cookieTitle = getCookie('seo_title');
    const cookieDescription = getCookie('seo_description');
    const cookieKeywords = getCookie('seo_keywords');

    if (cookieTitle && cookieDescription && cookieKeywords) {
      setSeoMetadata({
        title: cookieTitle,
        description: cookieDescription,
        keywords: cookieKeywords
      });
    }
  }, [title, description]);

  return (
    <>
      <Helmet>
        <title>{seoMetadata.title} | DISH Research</title>
        <meta name="description" content={seoMetadata.description} />
        <meta name="keywords" content={seoMetadata.keywords} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {children}
    </>
  );
};

function App() {
  return (
    <Router>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              DISH Research
            </Typography>
            <Box>
              <Button color="inherit" component={Link} to="/">Home</Button>
              <Button color="inherit" component={Link} to="/hsr">HSR</Button>
              <Button color="inherit" component={Link} to="/fcs">FCS</Button>
              <Button color="inherit" component={Link} to="/heni">HENI</Button>
              <Button color="inherit" component={Link} to="/environmental-impact">EnvI</Button>
              <Button color="inherit" component={Link} to="/net-health-impact">Net Impact</Button>
              <Button color="inherit" component={Link} to="/cnf-database">CNF DB</Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Container maxWidth="md" sx={{ flexGrow: 1, mt: 4, mb: 4 }}>
          <Routes>
            <Route path="/" element={
              <Layout title="Home" description="DISH Research - Innovative dietary impact and sustainability hub">
                <HomePage />
              </Layout>
            } />
            <Route path="/environmental-impact" element={
              <Layout title="Environmental Impact Calculator" description="Calculate the environmental impact of your meals with our advanced LCA tool.">
                <EnvironmentalImpact />
              </Layout>
            } />
            <Route path="/fcs" element={
              <Layout title="Food Compass Score Calculator" description="Calculate the Food Compass Score (FCS) for your food items.">
                <FCSCalculator />
              </Layout>
            } />
            <Route path="/heni" element={
              <Layout title="Health and Nutritional Impact Calculator" description="Calculate the Health and Nutritional Impact (HENI) score for your meals.">
                <HENICalculator />
              </Layout>
            } />
            <Route path="/hsr" element={
              <Layout title="Health Star Rating Calculator" description="Calculate the Health Star Rating (HSR) for your food items and meals.">
                <HSRCalculator />
              </Layout>
            } />
            <Route path="/net-health-impact" element={
              <Layout title="Net Health Impact Calculator" description="Calculate the combined health and environmental impact of your meals.">
                <NetHealthImpactCalculator />
              </Layout>
            } />
            <Route path="/cnf-database" element={
              <Layout title="CNF Database Manager" description="Manage and explore the Canadian Nutrient File database.">
                <CNFDatabaseManager />
              </Layout>
            } />
          </Routes>
        </Container>
      </Box>
    </Router>
  );
}

export default App;