import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { 
  TextField, Button, Typography, Paper, CircularProgress, Snackbar, 
  Grid, Divider, List, ListItem, ListItemText, IconButton, Tooltip, 
  Box, Card, CardContent, Container, Chip, Stepper, Step, StepLabel,
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import CalculateIcon from '@mui/icons-material/Calculate';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { API_ENDPOINTS } from '../config';
import FoodSearch from './FoodSearch';
import { Helmet } from 'react-helmet';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

// Helper function to get cookie value
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function EnvironmentalImpact() {
  const [foods, setFoods] = useState([]);
  const [currentFood, setCurrentFood] = useState({ food_id: '', name: '', quantity: '', unit: 'g' });
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [seoMetadata, setSeoMetadata] = useState({
    title: "Environmental Impact Calculator | DISH Research",
    description: "Calculate the environmental impact of your meals with our advanced LCA tool. Compare your meal's impact to reference meals and get monetized results.",
    keywords: "environmental impact, LCA, food sustainability, carbon footprint, meal comparison"
  });

  useEffect(() => {
    // Read SEO metadata from cookies set by the backend
    const cookieTitle = getCookie('seo_title');
    const cookieDescription = getCookie('seo_description');
    const cookieKeywords = getCookie('seo_keywords');

    if (cookieTitle && cookieDescription && cookieKeywords) {
      setSeoMetadata({
        title: cookieTitle,
        description: cookieDescription,
        keywords: cookieKeywords
      });
    }
  }, []);

  const handleFoodSelect = useCallback((foodId, foodName) => {
    setCurrentFood(prev => ({ ...prev, food_id: foodId, name: foodName }));
  }, []);

  const handleQuantityChange = useCallback((event) => {
    setCurrentFood(prev => ({ ...prev, quantity: event.target.value }));
  }, []);

  const handleUnitChange = useCallback((event) => {
    setCurrentFood(prev => ({ ...prev, unit: event.target.value }));
  }, []);

  const handleAddFood = useCallback(() => {
    if (currentFood.food_id && currentFood.quantity) {
      setFoods(prevFoods => [...prevFoods, currentFood]);
      setCurrentFood({ food_id: '', name: '', quantity: '', unit: 'g' });
    } else {
      setError('Please select a food and specify a quantity before adding.');
    }
  }, [currentFood]);

  const handleRemoveFood = useCallback((index) => {
    setFoods(prevFoods => prevFoods.filter((_, i) => i !== index));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (foods.length === 0) {
      setError('Please add at least one food item before calculating.');
      return;
    }
    setLoading(true);
    setError('');
    try {
      console.log('Sending food data:', foods);
      const response = await axios.post(API_ENDPOINTS.ENVIRONMENTAL_IMPACT, { 
        foods: foods.map(food => ({
          food_id: parseInt(food.food_id),
          quantity: parseFloat(food.quantity),
          unit: food.unit
        }))
      });
      console.log('Raw API Response:', response);
      console.log('API Response data:', response.data);
      if (response.data && response.data.data && response.data.data.data) {
        const {
          total_monetized_impact,
          meal_comparisons,
          midpoint_impacts,
          endpoint_impacts,
          monetized_impacts
        } = response.data.data.data;
        setResult({
          total_monetized_impact: typeof total_monetized_impact === 'number' ? total_monetized_impact : 'N/A',
          meal_comparisons: meal_comparisons || {},
          midpoint_impacts: midpoint_impacts || {},
          endpoint_impacts: endpoint_impacts || {},
          monetized_impacts: monetized_impacts || {}
        });
        // Update SEO metadata if available
        if (response.data.seo_metadata) {
          setSeoMetadata(response.data.seo_metadata);
        }
      } else {
        console.error('Unexpected API response structure:', response.data);
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error calculating environmental impact:', error);
      setError(error.response?.data?.error || 'Failed to calculate environmental impact. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const formatImpact = useCallback((value) => Number(value).toFixed(4), []);

  const renderImpactChart = useCallback((impacts, title) => {
    const data = Object.entries(impacts).map(([name, value]) => ({
      name: name.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
      value: parseFloat(value)
    })).sort((a, b) => b.value - a.value);

    return (
      <Card variant="outlined" sx={{ mt: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>{title}</Typography>
          <Box height={400}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={data} layout="vertical" margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={150} />
                <RechartsTooltip formatter={(value) => formatImpact(value)} />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </CardContent>
      </Card>
    );
  }, [formatImpact]);

  const renderMealComparisons = useCallback((comparisons) => {
    const data = Object.entries(comparisons).map(([name, value]) => ({
      name: name.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
      value: value * 100
    }));

    return (
      <Card variant="outlined" sx={{ mt: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>Meal Comparisons</Typography>
          <Box height={300}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name}: ${percent.toFixed(2)}%`}
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <RechartsTooltip formatter={(value) => `${value.toFixed(2)}%`} />
              </PieChart>
            </ResponsiveContainer>
          </Box>
          <List dense>
            {data.map((item, index) => (
              <ListItem key={item.name}>
                <Box width={20} height={20} bgcolor={COLORS[index % COLORS.length]} mr={2} />
                <ListItemText 
                  primary={item.name}
                  secondary={`${item.value.toFixed(2)}%`}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    );
  }, []);

  const activeStep = useMemo(() => {
    if (result) return 2;
    if (foods.length > 0) return 1;
    return 0;
  }, [foods, result]);

  return (
    <Container maxWidth="md">
      <Helmet>
        <title>{seoMetadata.title}</title>
        <meta name="description" content={seoMetadata.description} />
        <meta name="keywords" content={seoMetadata.keywords} />
      </Helmet>
      <Paper elevation={3} sx={{ padding: 3, mt: 4 }}>
        <Typography variant="h4" gutterBottom align="center">Environmental Impact Calculator</Typography>
        <Typography variant="body2" paragraph align="center">
          Estimate the environmental impact of your meal by adding food items and their quantities.
        </Typography>

        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          <Step><StepLabel>Add Foods</StepLabel></Step>
          <Step><StepLabel>Review Meal</StepLabel></Step>
          <Step><StepLabel>View Results</StepLabel></Step>
        </Stepper>
        
        <Card variant="outlined" sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>Add Foods to Your Meal</Typography>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={12}>
                <FoodSearch onFoodSelect={handleFoodSelect} />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Quantity"
                  type="number"
                  value={currentFood.quantity}
                  onChange={handleQuantityChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Unit</InputLabel>
                  <Select
                    value={currentFood.unit}
                    onChange={handleUnitChange}
                    label="Unit"
                  >
                    <MenuItem value="g">g</MenuItem>
                    <MenuItem value="ml">ml</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={handleAddFood}
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={!currentFood.food_id || !currentFood.quantity}
                  startIcon={<AddIcon />}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
            
            <List sx={{ mt: 2 }}>
              {foods.map((food, index) => (
                <ListItem key={index} sx={{ py: 1, bgcolor: index % 2 === 0 ? 'background.default' : 'background.paper' }}>
                  <ListItemText 
                    primary={food.name} 
                    secondary={`${food.quantity} ${food.unit}`} 
                    sx={{ flexGrow: 1 }}
                  />
                  <IconButton onClick={() => handleRemoveFood(index)} size="small">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>

        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <Button 
            onClick={handleSubmit} 
            variant="contained" 
            color="primary"
            disabled={loading || foods.length === 0}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <CalculateIcon />}
            size="large"
          >
            {loading ? 'Calculating...' : 'Calculate Environmental Impact'}
          </Button>
        </Box>

        {result && (
          <Box mt={4}>
            <Typography variant="h5" gutterBottom>Results</Typography>
            <Divider sx={{ my: 2 }} />
            
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>Total Monetized Impact</Typography>
                <Typography variant="h4" color="primary">
                  {result.total_monetized_impact !== 'N/A' 
                    ? `$${Number(result.total_monetized_impact).toFixed(2)}` 
                    : 'N/A'}
                  <Tooltip title="This represents the estimated economic cost of the environmental impact">
                    <Chip 
                      icon={<InfoIcon />}
                      label="What does this mean?"
                      size="small"
                      sx={{ ml: 2 }}
                    />
                  </Tooltip>
                </Typography>
              </CardContent>
            </Card>

            {Object.keys(result.meal_comparisons).length > 0 && renderMealComparisons(result.meal_comparisons)}
            {Object.keys(result.midpoint_impacts).length > 0 && renderImpactChart(result.midpoint_impacts, 'Midpoint Impacts')}
            {Object.keys(result.endpoint_impacts).length > 0 && renderImpactChart(result.endpoint_impacts, 'Endpoint Impacts')}
            {Object.keys(result.monetized_impacts).length > 0 && renderImpactChart(result.monetized_impacts, 'Monetized Impacts')}
          </Box>
        )}

        <Tooltip title="Learn more about Environmental Impact">
          <IconButton 
            color="primary" 
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            onClick={() => window.open('https://www.nature.com/articles/s43016-021-00343-4', '_blank')}
          >
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>

        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
          <Alert onClose={() => setError('')} severity="error" variant="filled">
            {error}
          </Alert>
        </Snackbar>
      </Paper>
    </Container>
  );
}

EnvironmentalImpact.propTypes = {
  // Add any props if needed
};

export default React.memo(EnvironmentalImpact);

// PropTypes for FoodSearch component (assumed to exist)
FoodSearch.propTypes = {
  onFoodSelect: PropTypes.func.isRequired,
};