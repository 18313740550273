import React, { useState, useCallback, useMemo } from 'react';
import { Box, Typography, Button, CircularProgress, Alert } from '@mui/material';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import FoodSearch from './FoodSearch';
import SelectedFoodDetails from './SelectedFoodDetails';
import AddFoodDialog from './AddFoodDialog';
import EditFoodDialog from './EditFoodDialog';
import useSnackbar from './hooks/useSnackbar';
import useFoodData from './hooks/useFoodData';
import ErrorBoundary from './ErrorBoundary';

const CNFDatabaseManager = () => {
  const { 
    selectedFood, 
    isLoading,
    error,
    handleFoodSelect,
    isAddFoodDialogOpen,
    setIsAddFoodDialogOpen,
    isEditFoodDialogOpen,
    setIsEditFoodDialogOpen,
    handleAddFood,
    handleEditFood,
    refreshSearchResults,
    foodGroups,
    foodSources,
    addFoodSource,
    nutrientSources,
    addNutrientSource,
    nutrients,
    measures
  } = useFoodData();

  const { Snackbar, showSnackbar } = useSnackbar();
  const [searchQuery, setSearchQuery] = useState('');

  const onFoodSelect = useCallback((foodId) => {
    handleFoodSelect(foodId);
  }, [handleFoodSelect]);

  const onAddFood = useCallback(async (foodData) => {
    try {
      const result = await handleAddFood(foodData);
      showSnackbar('Food added successfully', 'success');
      setIsAddFoodDialogOpen(false);
      await refreshSearchResults(searchQuery);
      if (result && result.food_id) {
        handleFoodSelect(result.food_id);
      }
    } catch (error) {
      showSnackbar('Failed to add food', 'error');
      // We don't rethrow the error here, as we've already handled it
    }
  }, [handleAddFood, refreshSearchResults, searchQuery, showSnackbar, setIsAddFoodDialogOpen, handleFoodSelect]);

  const onEditFood = useCallback(async (foodData) => {
    try {
      const result = await handleEditFood(foodData);
      showSnackbar('Food updated successfully', 'success');
      setIsEditFoodDialogOpen(false);
      await refreshSearchResults(searchQuery);
      if (result) {
        handleFoodSelect(result.FoodID);
      }
    } catch (error) {
      showSnackbar('Failed to update food', 'error');
    }
  }, [handleEditFood, refreshSearchResults, searchQuery, showSnackbar, setIsEditFoodDialogOpen, handleFoodSelect]);

  const memoizedSelectedFoodDetails = useMemo(() => (
    selectedFood && <SelectedFoodDetails selectedFood={selectedFood} />
  ), [selectedFood]);

  const memoizedAddFoodDialog = useMemo(() => (
    <AddFoodDialog 
      isOpen={isAddFoodDialogOpen}
      onClose={() => setIsAddFoodDialogOpen(false)}
      onAddFood={onAddFood}
      foodGroups={foodGroups}
      foodSources={foodSources}
      nutrients={nutrients}
      measures={measures}
      nutrientSources={nutrientSources}
      addNutrientSource={addNutrientSource}
      addFoodSource={addFoodSource}
    />
  ), [isAddFoodDialogOpen, setIsAddFoodDialogOpen, onAddFood, foodGroups, foodSources, nutrients, measures, nutrientSources, addNutrientSource, addFoodSource]);

  const memoizedEditFoodDialog = useMemo(() => (
    selectedFood && (
      <EditFoodDialog
        isOpen={isEditFoodDialogOpen}
        onClose={() => setIsEditFoodDialogOpen(false)}
        onEditFood={onEditFood}
        foodData={selectedFood}
        foodGroups={foodGroups}
        foodSources={foodSources}
        nutrients={nutrients}
        measures={measures}
        nutrientSources={nutrientSources}
        addNutrientSource={addNutrientSource}
      />
    )
  ), [isEditFoodDialogOpen, setIsEditFoodDialogOpen, onEditFood, selectedFood, foodGroups, foodSources, nutrients, measures, nutrientSources, addNutrientSource]);

  return (
    <ErrorBoundary>
      <Box>
        <Typography variant="h5" gutterBottom>CNF Database Manager</Typography>
        <FoodSearch onFoodSelect={onFoodSelect} onSearchQueryChange={setSearchQuery} />
        {isLoading && <CircularProgress />}
        {error && <Alert severity="error" sx={{ mt: 2, mb: 2 }}>{error}</Alert>}
        {selectedFood && memoizedSelectedFoodDetails}
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setIsAddFoodDialogOpen(true)}
          sx={{ mt: 2 }}
        >
          Add New Food
        </Button>
        {selectedFood && (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => setIsEditFoodDialogOpen(true)}
            sx={{ mt: 2, ml: 2 }}
          >
            Edit Selected Food
          </Button>
        )}
        {memoizedAddFoodDialog}
        {memoizedEditFoodDialog}
        <Snackbar />
      </Box>
    </ErrorBoundary>
  );
};

export default React.memo(CNFDatabaseManager);