// import React from 'react';
// import PropTypes from 'prop-types';
// import { Box, Typography, Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Divider } from '@mui/material';

// const SelectedFoodDetails = ({ selectedFood }) => (
//   <Box mt={2} border={1} borderColor="grey.300" borderRadius={2} p={2}>
//     <Typography variant="h6" gutterBottom>Selected Food Details</Typography>
//     <Grid container spacing={2}>
//       <Grid item xs={12} sm={6}>
//         <Typography><strong>Food ID:</strong> {selectedFood.FoodID}</Typography>
//         <Typography><strong>Description (English):</strong> {selectedFood.FoodDescription}</Typography>
//         <Typography><strong>Description (French):</strong> {selectedFood.FoodDescriptionF || 'N/A'}</Typography>
//         <Typography><strong>Food Code:</strong> {selectedFood.FoodCode}</Typography>
//       </Grid>
//       <Grid item xs={12} sm={6}>
//         <Typography><strong>Country Code:</strong> {selectedFood.CountryCode}</Typography>
//         <Typography><strong>Scientific Name:</strong> {selectedFood.ScientificName || 'N/A'}</Typography>
//         <Typography><strong>Food Group:</strong> {selectedFood.FoodGroupName}</Typography>
//         <Typography><strong>Food Source:</strong> {selectedFood.FoodSourceDescription}</Typography>
//       </Grid>
//     </Grid>
    
//     <Divider sx={{ my: 2 }} />
    
//     {selectedFood.NutrientValues && selectedFood.NutrientValues.length > 0 ? (
//       <Box mt={2}>
//         <Typography variant="subtitle1" gutterBottom>Nutrient Values (per 100g)</Typography>
//         <TableContainer component={Paper}>
//           <Table size="small">
//             <TableHead>
//               <TableRow>
//                 <TableCell>Nutrient</TableCell>
//                 <TableCell align="right">Value</TableCell>
//                 <TableCell>Unit</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {selectedFood.NutrientValues.map((nutrient, index) => (
//                 <TableRow key={index}>
//                   <TableCell>{nutrient.NutrientName}</TableCell>
//                   <TableCell align="right">{nutrient.NutrientValue}</TableCell>
//                   <TableCell>{nutrient.NutrientUnit}</TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>
//     ) : (
//       <Typography variant="body2" color="textSecondary">No nutrient values available.</Typography>
//     )}
    
//     <Divider sx={{ my: 2 }} />
    
//     {selectedFood.ConversionFactors && selectedFood.ConversionFactors.length > 0 ? (
//       <Box mt={2}>
//         <Typography variant="subtitle1" gutterBottom>Conversion Factors</Typography>
//         <TableContainer component={Paper}>
//           <Table size="small">
//             <TableHead>
//               <TableRow>
//                 <TableCell>Measure</TableCell>
//                 <TableCell align="right">Conversion Factor</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {selectedFood.ConversionFactors.map((factor, index) => (
//                 <TableRow key={index}>
//                   <TableCell>{factor.MeasureDescription}</TableCell>
//                   <TableCell align="right">{factor.ConversionFactorValue}</TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>
//     ) : (
//       <Typography variant="body2" color="textSecondary">No conversion factors available.</Typography>
//     )}
//   </Box>
// );

// SelectedFoodDetails.propTypes = {
//   selectedFood: PropTypes.shape({
//     FoodID: PropTypes.number.isRequired,
//     FoodDescription: PropTypes.string.isRequired,
//     FoodDescriptionF: PropTypes.string,
//     FoodCode: PropTypes.string.isRequired,
//     CountryCode: PropTypes.string.isRequired,
//     ScientificName: PropTypes.string,
//     FoodGroupName: PropTypes.string.isRequired,
//     FoodSourceDescription: PropTypes.string.isRequired,
//     NutrientValues: PropTypes.arrayOf(PropTypes.shape({
//       NutrientName: PropTypes.string.isRequired,
//       NutrientValue: PropTypes.number.isRequired,
//       NutrientUnit: PropTypes.string.isRequired
//     })),
//     ConversionFactors: PropTypes.arrayOf(PropTypes.shape({
//       MeasureDescription: PropTypes.string.isRequired,
//       ConversionFactorValue: PropTypes.number.isRequired
//     }))
//   }).isRequired
// };

// export default SelectedFoodDetails;


import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Divider } from '@mui/material';

const SelectedFoodDetails = ({ selectedFood }) => (
  <Box mt={2} border={1} borderColor="grey.300" borderRadius={2} p={2}>
    <Typography variant="h6" gutterBottom>Selected Food Details</Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Typography><strong>Food ID:</strong> {selectedFood.FoodID}</Typography>
        <Typography><strong>Description (English):</strong> {selectedFood.FoodDescription}</Typography>
        <Typography><strong>Description (French):</strong> {selectedFood.FoodDescriptionF || 'N/A'}</Typography>
        <Typography><strong>Food Code:</strong> {selectedFood.FoodCode}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography><strong>Country Code:</strong> {selectedFood.CountryCode || 'N/A'}</Typography>
        <Typography><strong>Scientific Name:</strong> {selectedFood.ScientificName || 'N/A'}</Typography>
        <Typography><strong>Food Group:</strong> {selectedFood.FoodGroupName || 'Unknown'}</Typography>
        <Typography><strong>Food Source:</strong> {selectedFood.FoodSourceDescription || 'Unknown'}</Typography>
      </Grid>
    </Grid>
    
    <Divider sx={{ my: 2 }} />
    
    {selectedFood.NutrientValues && selectedFood.NutrientValues.length > 0 ? (
      <Box mt={2}>
        <Typography variant="subtitle1" gutterBottom>Nutrient Values (per 100g)</Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Nutrient</TableCell>
                <TableCell align="right">Value</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Source</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedFood.NutrientValues.map((nutrient, index) => (
                <TableRow key={index}>
                  <TableCell>{nutrient.NutrientName}</TableCell>
                  <TableCell align="right">{nutrient.NutrientValue}</TableCell>
                  <TableCell>{nutrient.NutrientUnit}</TableCell>
                  <TableCell>{nutrient.NutrientSourceDescription || 'Unknown'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ) : (
      <Typography variant="body2" color="textSecondary">No nutrient values available.</Typography>
    )}
    
    <Divider sx={{ my: 2 }} />
    
    {selectedFood.ConversionFactors && selectedFood.ConversionFactors.length > 0 ? (
      <Box mt={2}>
        <Typography variant="subtitle1" gutterBottom>Conversion Factors</Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Measure</TableCell>
                <TableCell align="right">Conversion Factor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedFood.ConversionFactors.map((factor, index) => (
                <TableRow key={index}>
                  <TableCell>{factor.MeasureDescription || 'Unknown'}</TableCell>
                  <TableCell align="right">{factor.ConversionFactorValue}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ) : (
      <Typography variant="body2" color="textSecondary">No conversion factors available.</Typography>
    )}
  </Box>
);

SelectedFoodDetails.propTypes = {
  selectedFood: PropTypes.shape({
    FoodID: PropTypes.number.isRequired,
    FoodDescription: PropTypes.string.isRequired,
    FoodDescriptionF: PropTypes.string,
    FoodCode: PropTypes.string.isRequired,
    CountryCode: PropTypes.string,
    ScientificName: PropTypes.string,
    FoodGroupName: PropTypes.string,
    FoodSourceDescription: PropTypes.string,
    NutrientValues: PropTypes.arrayOf(PropTypes.shape({
      NutrientName: PropTypes.string.isRequired,
      NutrientValue: PropTypes.number.isRequired,
      NutrientUnit: PropTypes.string,
      NutrientSourceDescription: PropTypes.string
    })),
    ConversionFactors: PropTypes.arrayOf(PropTypes.shape({
      MeasureDescription: PropTypes.string,
      ConversionFactorValue: PropTypes.number.isRequired
    }))
  }).isRequired
};

export default SelectedFoodDetails;