import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions, 
  Button, Typography, CircularProgress
} from '@mui/material';
import StepIndicator from './StepIndicator';
import BasicInfoStep from './BasicInfoStep';
import NutrientValuesStep from './NutrientValuesStep';
import ConversionFactorsStep from './ConversionFactorsStep';
import useSnackbar from './hooks/useSnackbar';

const steps = ['Basic Info', 'Nutrient Values', 'Conversion Factors'];

const AddFoodDialog = ({ isOpen, onClose, onAddFood, foodGroups, foodSources, nutrients, measures, nutrientSources, addNutrientSource, addFoodSource }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [newFoodData, setNewFoodData] = useState({
    FoodDescription: '',
    FoodDescriptionF: '',
    FoodGroupIDs: [],
    FoodSourceID: '',
    CountryCode: '',
    ScientificName: '',
    NutrientValues: [],
    ConversionFactors: []
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // const [newSourceDialogOpen, setNewSourceDialogOpen] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setNewFoodData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  }, []);

  const handleTranslate = useCallback(async () => {
    if (!newFoodData.FoodDescription) return;
    setIsLoading(true);
    try {
      const response = await fetch('https://api-research.ecodish365.com/cnf/translate/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: newFoodData.FoodDescription }),
      });
      const data = await response.json();
      setNewFoodData(prev => ({ ...prev, FoodDescriptionF: data.translation }));
    } catch (error) {
      showSnackbar('Error translating text', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [newFoodData.FoodDescription, showSnackbar]);

  const handleAddNutrientSource = useCallback(async (description) => {
    try {
      const newSource = await addNutrientSource(description);
      showSnackbar('Nutrient source added successfully', 'success');
      return newSource;
    } catch (error) {
      showSnackbar('Failed to add nutrient source', 'error');
    }
  }, [addNutrientSource, showSnackbar]);

  const handleAddFoodSource = useCallback(async (description) => {
    console.log('Attempting to add food source with description:', description);

    try {
        const newSource = await addFoodSource(description);
        console.log('Food source added successfully:', newSource);
        showSnackbar('Food source added successfully', 'success');
        return newSource;
    } catch (error) {
        console.error('Failed to add food source:', error);
        showSnackbar('Failed to add food source', 'error');
        throw error;
    }
}, [addFoodSource, showSnackbar]);

  const validateStep = useCallback(() => {
    const currentErrors = {};
    switch (activeStep) {
      case 0:
        if (!newFoodData.FoodDescription) currentErrors.FoodDescription = 'Required';
        if (!newFoodData.FoodDescriptionF) currentErrors.FoodDescriptionF = 'Required';
        if (newFoodData.FoodGroupIDs.length === 0) currentErrors.FoodGroupIDs = 'At least one food group is required';
        if (!newFoodData.FoodSourceID) currentErrors.FoodSourceID = 'Required';
        if (!newFoodData.CountryCode) currentErrors.CountryCode = 'Required';
        break;
      case 1:
        if (newFoodData.NutrientValues.length === 0) currentErrors.NutrientValues = 'At least one nutrient value is required';
        break;
      case 2:
        if (newFoodData.ConversionFactors.length === 0) currentErrors.ConversionFactors = 'At least one conversion factor is required';
        break;
      default:
        break;
    }
    setErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  }, [activeStep, newFoodData]);

  const handleNext = useCallback(() => {
    if (validateStep()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [validateStep]);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const handleAddFood = async () => {
    if (validateStep()) {
      setIsLoading(true);
      try {
        await onAddFood(newFoodData);
        // The dialog will be closed by the parent component
      } catch (error) {
        showSnackbar('Error adding food. Please try again.', 'error');
      } finally {
        setIsLoading(false);
      }
    }
  };
  
  const handleNutrientChange = useCallback((index, field, value) => {
    setNewFoodData(prev => {
      const updatedNutrients = [...prev.NutrientValues];
      updatedNutrients[index] = { ...updatedNutrients[index], [field]: value };
      return { ...prev, NutrientValues: updatedNutrients };
    });
  }, []);

  const addNutrientValue = useCallback(() => {
    setNewFoodData(prev => ({
      ...prev,
      NutrientValues: [...prev.NutrientValues, { NutrientID: '', NutrientValue: '', NutrientSourceID: '' }]
    }));
  }, []);

  const removeNutrientValue = useCallback((index) => {
    setNewFoodData(prev => ({
      ...prev,
      NutrientValues: prev.NutrientValues.filter((_, i) => i !== index)
    }));
  }, []);

  const handleConversionFactorChange = useCallback((index, field, value) => {
    setNewFoodData(prev => {
      const updatedFactors = [...prev.ConversionFactors];
      updatedFactors[index] = { 
        ...updatedFactors[index], 
        [field]: field === 'MeasureID' ? Number(value) : value 
      };
      return { ...prev, ConversionFactors: updatedFactors };
    });
  }, []);

  const addConversionFactor = useCallback(() => {
    setNewFoodData(prev => ({
      ...prev,
      ConversionFactors: [...prev.ConversionFactors, { MeasureID: '', ConversionFactorValue: '' }]
    }));
  }, []);

  const removeConversionFactor = useCallback((index) => {
    setNewFoodData(prev => ({
      ...prev,
      ConversionFactors: prev.ConversionFactors.filter((_, i) => i !== index)
    }));
  }, []);

  const stepContent = useMemo(() => {
    switch (activeStep) {
      case 0:
        return (
          <BasicInfoStep
            foodData={newFoodData}
            onChange={handleInputChange}
            onTranslate={handleTranslate}
            foodGroups={foodGroups}
            foodSources={foodSources}
            errors={errors}
            isLoading={isLoading}
            onCreateNewFoodSource={handleAddFoodSource}
          />
        );
      case 1:
        return (
          <NutrientValuesStep
            nutrientValues={newFoodData.NutrientValues}
            onChange={handleNutrientChange}
            onAdd={addNutrientValue}
            onRemove={removeNutrientValue}
            nutrients={nutrients}
            nutrientSources={nutrientSources}
            addNutrientSource={handleAddNutrientSource}
            errors={errors}
          />
        );
      case 2:
        return (
          <ConversionFactorsStep
            conversionFactors={newFoodData.ConversionFactors}
            onChange={handleConversionFactorChange}
            onAdd={addConversionFactor}
            onRemove={removeConversionFactor}
            measures={measures}
            errors={errors}
          />
        );
      default:
        return null;
    }
  }, [activeStep, newFoodData, handleInputChange, handleTranslate, foodGroups, foodSources, errors, isLoading, handleNutrientChange, addNutrientValue, removeNutrientValue, nutrients, nutrientSources, handleAddNutrientSource, handleConversionFactorChange, addConversionFactor, removeConversionFactor, measures, handleAddFoodSource]);

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h5">Add New Food</Typography>
        <StepIndicator steps={steps} activeStep={activeStep} />
      </DialogTitle>
      <DialogContent>
        {stepContent}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {activeStep > 0 && <Button onClick={handleBack}>Back</Button>}
        {activeStep < steps.length - 1 ? (
          <Button onClick={handleNext} variant="contained" color="primary">
            Next
          </Button>
        ) : (
          <Button onClick={handleAddFood} variant="contained" color="primary" disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : 'Add Food'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

AddFoodDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddFood: PropTypes.func.isRequired,
  foodGroups: PropTypes.array.isRequired,
  foodSources: PropTypes.array.isRequired,
  nutrients: PropTypes.array.isRequired,
  measures: PropTypes.array.isRequired,
  nutrientSources: PropTypes.array.isRequired,
  addNutrientSource: PropTypes.func.isRequired,
  addFoodSource: PropTypes.func.isRequired,
};

export default AddFoodDialog;
