import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { 
  Button, Typography, Paper, Grid, Snackbar,
  CircularProgress, List, ListItem, ListItemText, Box, Chip, Tooltip,
  Card, CardContent, Divider, Container, IconButton, Accordion, AccordionSummary, AccordionDetails,
  Stepper, Step, StepLabel
} from '@mui/material';
import { Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import CalculateIcon from '@mui/icons-material/Calculate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { API_ENDPOINTS } from '../config';
import FoodSearch from './FoodSearch';
import { Helmet } from 'react-helmet';

// Helper function to get cookie value
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const FCSCalculator = () => {
  const [foods, setFoods] = useState([]);
  const [currentFood, setCurrentFood] = useState({ id: '', name: '' });
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [seoMetadata, setSeoMetadata] = useState({
    title: "Food Composition Score Calculator | DISH Research",
    description: "Calculate the Food Composition Score (FCS) for your food items. Analyze nutritional content and get detailed FCS results.",
    keywords: "FCS, food composition score, nutritional analysis, food science, DISH Research"
  });

  useEffect(() => {
    // Read SEO metadata from cookies set by the backend
    const cookieTitle = getCookie('seo_title');
    const cookieDescription = getCookie('seo_description');
    const cookieKeywords = getCookie('seo_keywords');

    if (cookieTitle && cookieDescription && cookieKeywords) {
      setSeoMetadata({
        title: cookieTitle,
        description: cookieDescription,
        keywords: cookieKeywords
      });
    }
  }, []);

  const handleFoodSelect = useCallback((foodId, foodName) => {
    setCurrentFood({ id: foodId, name: foodName });
  }, []);

  const handleAddFood = useCallback(() => {
    if (currentFood.id) {
      setFoods(prevFoods => [...prevFoods, currentFood]);
      setCurrentFood({ id: '', name: '' });
    } else {
      setError('Please select a food before adding.');
    }
  }, [currentFood]);

  const handleRemoveFood = useCallback((index) => {
    setFoods(prevFoods => prevFoods.filter((_, i) => i !== index));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (foods.length === 0) {
      setError('Please add at least one food item before calculating.');
      return;
    }
    setLoading(true);
    setError('');
    try {
      const response = await axios.post(API_ENDPOINTS.FCS_CALCULATE, {
        food_ids: foods.map(food => food.id), food_names: foods.map(food => food.name),
      });
      console.log('API Response:', response.data);  // Log the entire response
      if (response.data && response.data.data && response.data.data.data) {
        setResult(response.data.data.data);
        // Update SEO metadata if available
        if (response.data.seo_metadata) {
          setSeoMetadata(response.data.seo_metadata);
        }
      } else {
        console.error('Unexpected API response structure:', response.data);
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error calculating FCS:', error);
      setError(error.response?.data?.error || 'Failed to calculate FCS. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const interpretFCS = useMemo(() => (score) => {
    if (score >= 70) return { text: "Most healthful", color: "success", description: "Highly nutritious and beneficial" };
    if (score >= 50) return { text: "More healthful", color: "info", description: "Good nutritional value" };
    if (score >= 30) return { text: "Moderately healthful", color: "warning", description: "Moderate nutritional value" };
    return { text: "Least healthful", color: "error", description: "Limited nutritional value" };
  }, []);

  const interpretNOVA = useMemo(() => (category) => {
    const interpretations = {
      MINIMALLY_PROCESSED: "Unprocessed or minimally processed foods",
      PROCESSED_CULINARY: "Processed culinary ingredients",
      PROCESSED: "Processed foods",
      ULTRA_PROCESSED: "Ultra-processed foods"
    };
    return interpretations[category] || "Unknown category";
  }, []);

  const activeStep = useMemo(() => {
    if (result) return 2;
    if (foods.length > 0) return 1;
    return 0;
  }, [foods, result]);

  return (
    <Container maxWidth="md">
      <Helmet>
        <title>{seoMetadata.title}</title>
        <meta name="description" content={seoMetadata.description} />
        <meta name="keywords" content={seoMetadata.keywords} />
      </Helmet>
      <Paper elevation={3} sx={{ padding: 3, mt: 4 }}>
        <Typography variant="h4" gutterBottom align="center">Food Compass Score (FCS) Calculator</Typography>
        <Typography variant="body2" paragraph align="center">
          Calculate the Food Compass Score to assess the healthfulness of food items based on various nutritional attributes.
        </Typography>

        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          <Step><StepLabel>Add Foods</StepLabel></Step>
          <Step><StepLabel>Review Selection</StepLabel></Step>
          <Step><StepLabel>View Results</StepLabel></Step>
        </Stepper>
        
        <Card variant="outlined" sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>Add Foods to Calculate</Typography>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={12}>
                <FoodSearch onFoodSelect={handleFoodSelect} />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={handleAddFood}
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={!currentFood.id}
                >
                  Add Food
                </Button>
              </Grid>
            </Grid>
            
            <List sx={{ mt: 2 }}>
              {foods.map((food, index) => (
                <ListItem key={index} sx={{ py: 1, bgcolor: index % 2 === 0 ? 'background.default' : 'background.paper' }}>
                  <ListItemText 
                    primary={food.name}
                    secondary={`ID: ${food.id}`}
                    sx={{ flexGrow: 1 }}
                  />
                  <IconButton onClick={() => handleRemoveFood(index)} size="small">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>

        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <Button 
            onClick={handleSubmit} 
            variant="contained" 
            color="primary"
            disabled={loading || foods.length === 0}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <CalculateIcon />}
            size="large"
          >
            {loading ? 'Calculating...' : 'Calculate FCS'}
          </Button>
        </Box>

        {result && (
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>Results</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Food Name: {result.name || foods[0]?.name || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  Original Score: {result.original_score?.toFixed(2) || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  Food Compass Score (FCS): {result.fcs?.toFixed(2) || 'N/A'}
                  <Tooltip title={interpretFCS(result.fcs || 0).description}>
                    <Chip 
                      label={interpretFCS(result.fcs || 0).text} 
                      color={interpretFCS(result.fcs || 0).color}
                      size="small"
                      sx={{ ml: 1 }}
                      icon={<InfoIcon />}
                    />
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  NOVA Category: {result.nova_category || 'N/A'}
                  <Tooltip title={interpretNOVA(result.nova_category || '')}>
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
            </Grid>
              <Divider sx={{ my: 2 }} />
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>What do these scores mean?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" paragraph>
                    <strong>Original Score:</strong> Calculated based on 46 attributes across 9 domains including nutrient ratios, vitamins, minerals, food ingredients, additives, processing, specific lipids, fibers, proteins, and phytochemicals. It ranges from -26.1 to 10.6.
                  </Typography>
                  <Typography variant="body2" paragraph>
                    <strong>Food Compass Score (FCS):</strong> Derived from the Original Score and ranges from 1 (least healthful) to 100 (most healthful). It's calculated using the formula: FCS = 1 + 99 * ((Original Score + 26.1) / 36.7)
                  </Typography>
                  <Typography variant="body2">
                    <strong>NOVA Category:</strong> Classifies foods according to the extent and purpose of food processing, rather than in terms of nutrients.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        )}

        <Tooltip title="Learn more about Food Compass Score">
          <IconButton 
            color="primary" 
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            onClick={() => window.open('https://www.nature.com/articles/s43016-021-00381-y', '_blank')}
          >
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>

        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
          <Alert onClose={() => setError('')} severity="error" variant="filled">
            {error}
          </Alert>
        </Snackbar>
      </Paper>
    </Container>
  );
};

FCSCalculator.propTypes = {
  // Add any props if needed
};

export default React.memo(FCSCalculator);

// PropTypes for FoodSearch component (assumed to exist)
FoodSearch.propTypes = {
  onFoodSelect: PropTypes.func.isRequired,
};