import React from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepLabel } from '@mui/material';

const StepIndicator = ({ steps, activeStep }) => (
  <Stepper activeStep={activeStep} alternativeLabel sx={{ mt: 2, mb: 4 }}>
    {steps.map((label, index) => (
      <Step key={label} completed={index < activeStep}>
        <StepLabel>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>
);

StepIndicator.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeStep: PropTypes.number.isRequired
};

export default StepIndicator;