import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardActions, 
  Button, 
  Box,
  Paper,
  Chip,
  Divider
} from '@mui/material';
import { 
  Calculate as CalculateIcon,
  Nature as NatureIcon,
  Restaurant as RestaurantIcon,
  HealthAndSafety as HealthIcon,
  Star as StarIcon,
  Balance as BalanceIcon,
  Storage as StorageIcon,
  TrendingUp as TrendingUpIcon
} from '@mui/icons-material';
import { Helmet } from 'react-helmet';

// Define calculator configurations
const calculators = [
  {
    name: 'HSR Calculator',
    description: 'Calculate the Health Star Rating for food products.',
    icon: <StarIcon fontSize="large" />,
    path: '/hsr',
    color: '#FFA000'
  },
  {
    name: 'FCS Calculator',
    description: 'Determine the Food Consumption Score for dietary diversity.',
    icon: <RestaurantIcon fontSize="large" />,
    path: '/fcs',
    color: '#4CAF50'
  },
  {
    name: 'HENI Calculator',
    description: 'Estimate the Health Nutritional Index of foods.',
    icon: <HealthIcon fontSize="large" />,
    path: '/heni',
    color: '#2196F3'
  },
  {
    name: 'Environmental Impact Calculator',
    description: 'Assess the environmental impact of food choices.',
    icon: <NatureIcon fontSize="large" />,
    path: '/environmental-impact',
    color: '#009688'
  },
  {
    name: 'Net Health Impact Calculator',
    description: 'Calculate the overall health impact considering nutrition and environment.',
    icon: <BalanceIcon fontSize="large" />,
    path: '/net-health-impact',
    color: '#9C27B0'
  },
  {
    name: 'CNF Database Manager',
    description: 'Manage the Canadian Nutrient File database.',
    icon: <StorageIcon fontSize="large" />,
    path: '/cnf-database',
    color: '#FF5722'
  }
];

// Calculator Card component
const CalculatorCard = React.memo(({ calc }) => (
  <Card raised sx={{ 
    height: '100%', 
    display: 'flex', 
    flexDirection: 'column', 
    transition: '0.3s', 
    '&:hover': { transform: 'scale(1.03)' } 
  }}>
    <CardContent sx={{ flexGrow: 1 }}>
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          mb: 2,
          color: calc.color
        }}
      >
        {calc.icon}
        <Typography variant="h6" component="div" sx={{ ml: 1, fontWeight: 'bold' }}>
          {calc.name}
        </Typography>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Typography variant="body2" color="text.secondary">
        {calc.description}
      </Typography>
    </CardContent>
    <CardActions>
      <Button 
        fullWidth
        variant="contained"
        size="large" 
        component={RouterLink} 
        to={calc.path}
        endIcon={<CalculateIcon />}
        sx={{ 
          backgroundColor: calc.color, 
          '&:hover': { backgroundColor: calc.color, opacity: 0.9 } 
        }}
      >
        Use Tool
      </Button>
    </CardActions>
  </Card>
));

CalculatorCard.propTypes = {
  calc: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    path: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
};

CalculatorCard.displayName = 'CalculatorCard';

// Main HomePage component
const HomePage = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "DISH Research",
    "description": "Innovative dietary impact and sustainability hub",
    "url": "https://www.dishresearch.com",
    "tool": calculators.map(calc => ({
      "@type": "WebApplication",
      "name": calc.name,
      "description": calc.description,
      "url": `https://www.dishresearch.com${calc.path}`
    }))
  };

  return (
    <Box>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Paper elevation={3} sx={{ p: 4, mb: 4, backgroundColor: '#f0f8ff' }}>
        <Typography variant="h3" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
          Welcome to DISH For Research
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary" paragraph>
          Explore our suite of nutrition and environmental impact tools.
          Make informed decisions about nutrition and its impact on health and the environment.
        </Typography>
        <Box display="flex" justifyContent="center" mt={2}>
          <Chip 
            icon={<TrendingUpIcon />} 
            label="Most Popular: HSR Calculator" 
            color="primary" 
          />
        </Box>
      </Paper>
      
      <Grid container spacing={3}>
        {calculators.map((calc) => (
          <Grid item xs={12} sm={6} md={4} key={calc.name}>
            <CalculatorCard calc={calc} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default React.memo(HomePage);