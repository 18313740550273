import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions, 
  Button, Typography, CircularProgress, Stepper, Step, StepLabel
} from '@mui/material';
import BasicInfoStep from './BasicInfoStep';
import NutrientValuesStep from './NutrientValuesStep';
import ConversionFactorsStep from './ConversionFactorsStep';
import useSnackbar from './hooks/useSnackbar';

const steps = ['Basic Info', 'Nutrient Values', 'Conversion Factors'];

const EditFoodDialog = ({ 
  isOpen, 
  onClose, 
  foodData, 
  onEditFood, 
  foodGroups, 
  foodSources, 
  nutrients, 
  measures, 
  nutrientSources, 
  addNutrientSource,
  addFoodSource
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [editedFoodData, setEditedFoodData] = useState({
    ...foodData,
    FoodGroupIDs: foodData.FoodGroupIDs || [],
    NutrientValues: foodData.NutrientValues || [],
    ConversionFactors: foodData.ConversionFactors || []
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const { showSnackbar } = useSnackbar();

  

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setEditedFoodData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  }, []);

  const handleNutrientChange = useCallback((index, field, value) => {
    setEditedFoodData(prev => {
      const updatedNutrients = [...prev.NutrientValues];
      updatedNutrients[index] = { ...updatedNutrients[index], [field]: value };
      return { ...prev, NutrientValues: updatedNutrients };
    });
  }, []);

  const handleConversionFactorChange = useCallback((index, field, value) => {
    setEditedFoodData(prev => {
      const updatedFactors = [...prev.ConversionFactors];
      updatedFactors[index] = { 
        ...updatedFactors[index], 
        [field]: field === 'MeasureID' ? Number(value) : value 
      };
      return { ...prev, ConversionFactors: updatedFactors };
    });
  }, []);

  const validateStep = useCallback(() => {
    const currentErrors = {};
    switch (activeStep) {
      case 0:
        if (!editedFoodData.FoodDescription) currentErrors.FoodDescription = 'Required';
        if (!editedFoodData.FoodDescriptionF) currentErrors.FoodDescriptionF = 'Required';
        if (editedFoodData.FoodGroupIDs.length === 0) currentErrors.FoodGroupIDs = 'At least one food group is required';
        if (!editedFoodData.FoodSourceID) currentErrors.FoodSourceID = 'Required';
        if (!editedFoodData.CountryCode) currentErrors.CountryCode = 'Required';
        break;
      case 1:
        if (editedFoodData.NutrientValues.length === 0) currentErrors.NutrientValues = 'At least one nutrient value is required';
        break;
      case 2:
        if (editedFoodData.ConversionFactors.length === 0) currentErrors.ConversionFactors = 'At least one conversion factor is required';
        break;
      default:
        break;
    }
    setErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  }, [activeStep, editedFoodData]);

  const handleNext = useCallback(() => {
    if (validateStep()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [validateStep]);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const handleEditFood = async () => {
    if (validateStep()) {
      setIsSubmitting(true);
      try {
        await onEditFood(editedFoodData);
        showSnackbar('Food updated successfully', 'success');
        onClose();
      } catch (error) {
        console.error('Error editing food:', error);
        showSnackbar('Failed to update food. Please try again.', 'error');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const stepContent = useMemo(() => {
    switch (activeStep) {
      case 0:
        return (
          <BasicInfoStep
            foodData={editedFoodData}
            onChange={handleInputChange}
            foodGroups={foodGroups}
            foodSources={foodSources}
            errors={errors}
            onCreateNewFoodSource={addFoodSource}
          />
        );
      case 1:
        return (
          <NutrientValuesStep
            nutrientValues={editedFoodData.NutrientValues}
            onChange={handleNutrientChange}
            onAdd={() => {/* Add new nutrient value */}}
            onRemove={() => {/* Remove nutrient value */}}
            nutrients={nutrients}
            nutrientSources={nutrientSources}
            addNutrientSource={addNutrientSource}
            errors={errors}
          />
        );
      case 2:
        return (
          <ConversionFactorsStep
            conversionFactors={editedFoodData.ConversionFactors}
            onChange={handleConversionFactorChange}
            onAdd={() => {/* Add new conversion factor */}}
            onRemove={() => {/* Remove conversion factor */}}
            measures={measures}
            errors={errors}
          />
        );
      default:
        return null;
    }
  }, [activeStep, editedFoodData, handleInputChange, handleNutrientChange, handleConversionFactorChange, foodGroups, foodSources, nutrients, nutrientSources, measures, errors, addFoodSource, addNutrientSource]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h5">Edit Food</Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>
      <DialogContent>
        {stepContent}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSubmitting}>Cancel</Button>
        {activeStep > 0 && <Button onClick={handleBack}>Back</Button>}
        {activeStep < steps.length - 1 ? (
          <Button onClick={handleNext} variant="contained" color="primary">
            Next
          </Button>
        ) : (
          <Button 
            onClick={handleEditFood} 
            variant="contained" 
            color="primary" 
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          >
            {isSubmitting ? 'Saving...' : 'Save Changes'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

EditFoodDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  foodData: PropTypes.object.isRequired,
  onEditFood: PropTypes.func.isRequired,
  foodGroups: PropTypes.array.isRequired,
  foodSources: PropTypes.array.isRequired,
  nutrients: PropTypes.array.isRequired,
  measures: PropTypes.array.isRequired,
  nutrientSources: PropTypes.array.isRequired,
  addNutrientSource: PropTypes.func.isRequired,
  addFoodSource: PropTypes.func.isRequired
};

export default EditFoodDialog;